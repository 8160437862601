var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMobile())?_c('v-app',{style:(_vm.$root.lang == 'ar' ? _vm.merkazi : _vm.poppins)},[_c('v-snackbar',{staticStyle:{"z-index":"1000"},attrs:{"timeout":5000,"value":true,"absolute":"","top":"","rounded":"","multi-line":"","color":"blue-grey darken-1","elevation":"24"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}],null,false,1343050862),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('v-avatar',{attrs:{"color":"white","size":"50","dark":""}},[_c('v-img',{attrs:{"src":"/media/logos/logo.png","contain":"","height":"30"}})],1),_c('div',{staticClass:"px-2",staticStyle:{"font-size":"1.1rem","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)]),_c('v-app-bar',{attrs:{"app":"","color":"rgb(238, 240, 248)","elevation":"0"}},[_c('KTBrand'),_c('span',{staticClass:"font-size-h4 text-primary mx-2",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(this.$root.title))]),_c('v-spacer'),_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-btn',{staticClass:"mx-0",class:isActive ? 'white' : 'amber darken-1',attrs:{"href":href,"icon":"","width":"35","height":"35"},on:{"click":navigate}},[(_vm.$user.getCurrentUser())?_c('v-avatar',{attrs:{"size":"35"}},[(_vm.$user.getCurrentUser().avatar != null)?_c('v-img',{attrs:{"src":_vm.$root.apiURL +
                'assets/' +
                _vm.$user.getCurrentUser().avatar.private_hash}}):_c('unicon',{attrs:{"name":"user-circle","fill":isActive ? '#FFB300' : '#FFFFFF',"height":"22px","width":"22px"}})],1):_vm._e()],1)]}}],null,false,1470143538)})],1),_c('v-main',[_c('v-container',{class:_vm.$root.currentpage === 'dashboard' ? 'pt-0' : '',style:('padding-bottom: ' + this.$root.tabspadding + 'px;'),attrs:{"fluid":""}},[_c('keep-alive',{attrs:{"include":"dashboard, PatientDashboard, file, patient_info"}},[_c('router-view')],1)],1)],1),_c('v-footer',{style:('background-color: white;padding-bottom: ' + _vm.tabspadding * 0 + 'px;'),attrs:{"fixed":"","padless":""}},[_c('Menu')],1)],1):_c('v-app',{staticStyle:{"width":"100% !important"},style:(_vm.$root.lang == 'ar' ? _vm.merkazi : '')},[_c('v-snackbar',{staticClass:"rounded-lg",staticStyle:{"z-index":"1000"},attrs:{"timeout":5000,"value":true,"absolute":"","right":"","top":"","multi-line":"","color":"blue-grey darken-1","elevation":"24"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('v-avatar',{attrs:{"color":"white","size":"50","dark":""}},[_c('v-img',{attrs:{"src":"/media/logos/logo.png","contain":"","height":"30"}})],1),_c('div',{staticClass:"px-2",staticStyle:{"font-size":"1.1rem","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)]),_c('v-navigation-drawer',{staticStyle:{"z-index":"10000"},attrs:{"permanent":"","color":"primary","expand-on-hover":"","app":"","right":_vm.$root.lang == 'ar'},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
return [_c('v-list',[_c('v-divider',{staticClass:"my-0"}),_c('v-list-item',{staticClass:"px-2",attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item-avatar',{attrs:{"size":"40"}},[(_vm.$user.getCurrentUser())?_c('v-avatar',{attrs:{"tile":"","size":"40"}},[(_vm.$user.getCurrentUser().avatar != null)?_c('v-img',{attrs:{"src":_vm.$root.apiURL +
                      'assets/' +
                      _vm.$user.getCurrentUser().avatar.private_hash}}):_c('unicon',{attrs:{"name":"user-circle","fill":isActive ? '#FFB300' : '#FFFFFF'}})],1):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$user.getCurrentUser().first_name + " " + _vm.$user.getCurrentUser().last_name)+" ")])],1)],1)],1)]}}])})]},proxy:true}])},[_c('KTBrand',{staticClass:"pt-3 px-2"}),_c('KTAside')],1),_c('v-slide-x-transition',[_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$root.admin.openside),expression:"$root.admin.openside"}],style:(_vm.$root.lang == 'ar' ? 'margin-right:56px' : 'margin-left:56px'),attrs:{"right":_vm.$root.lang == 'ar',"width":"300px","permanent":"","app":""}},[(_vm.$root.currentpage == 'users')?_c('RoleList'):_vm._e(),(_vm.$root.currentpage == 'AppSettings')?_c('SettingsList'):_vm._e(),(_vm.$root.currentpage == 'chat')?_c('Conversations'):_vm._e()],1)],1),_c('AppTop'),_c('v-main',{style:(_vm.$root.lang == 'ar'
        ? 'padding-right:' + _vm.$root.admin.extramargin
        : 'padding-left:' + _vm.$root.admin.extramargin)},[_c('v-container',{attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)],1),(_vm.$root.currentpage != 'chat')?_c('v-footer',{style:(_vm.$root.lang == 'ar'
        ? 'right:' + _vm.$root.admin.extramargin
        : 'left:' + _vm.$root.admin.extramargin),attrs:{"app":"","inset":""}},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-between",staticStyle:{"width":"100%"}},[_c('div',{},[_c('span',{staticClass:"text-muted font-weight-bold mr-2"},[_vm._v("2022©")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"target":"_blank"}},[_vm._v("Doctory Care")])]),_c('v-spacer'),_c('div',{staticStyle:{"width":"fit-content","display":"flex"}},[_c('a',{staticClass:"nav-link pr-3 pl-0",attrs:{"target":"_blank"}},[_vm._v("About")]),_c('a',{staticClass:"nav-link px-3",attrs:{"target":"_blank"}},[_vm._v("Team")]),_c('router-link',{attrs:{"to":'/contact'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link px-3",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Contact ")])]}}],null,false,605805012)})],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }