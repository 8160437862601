<template>
  <v-app v-if="isMobile()" :style="$root.lang == 'ar' ? merkazi : poppins">
    <v-snackbar
      :timeout="5000"
      :value="true"
      absolute
      top
      rounded=""
      multi-line
      color="blue-grey darken-1"
      elevation="24"
      v-model="snackbar"
      style="z-index: 1000"
    >
      <div style="display: flex; flex-direction: row; align-items: center">
        <v-avatar color="white" size="50" dark
          ><v-img src="/media/logos/logo.png" contain height="30"
        /></v-avatar>
        <div style="font-size: 1.1rem; font-weight: 500" class="px-2">
          {{ msg }}
        </div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-app-bar app color="rgb(238, 240, 248)" elevation="0">
      <KTBrand></KTBrand>
      <span class="font-size-h4 text-primary mx-2" style="font-weight: 500">{{
        this.$root.title
      }}</span>
      <v-spacer />

      <router-link to="/profile" v-slot="{ href, navigate, isActive }">
        <v-btn
          @click="navigate"
          :href="href"
          icon
          width="35"
          height="35"
          class="mx-0"
          :class="isActive ? 'white' : 'amber darken-1'"
        >
          <v-avatar v-if="$user.getCurrentUser()" size="35">
            <v-img
              v-if="$user.getCurrentUser().avatar != null"
              :src="
                $root.apiURL +
                  'assets/' +
                  $user.getCurrentUser().avatar.private_hash
              "
            ></v-img>
            <unicon
              v-else
              name="user-circle"
              :fill="isActive ? '#FFB300' : '#FFFFFF'"
              height="22px"
              width="22px"
            ></unicon>
          </v-avatar>
        </v-btn>
      </router-link>

      <!--end: Language bar -->
      <!--begin::User-->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container
        fluid
        :class="$root.currentpage === 'dashboard' ? 'pt-0' : ''"
        :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
      >
        <!-- If using vue-router -->
        <keep-alive include="dashboard, PatientDashboard, file, patient_info">
          <router-view></router-view>
        </keep-alive>
      </v-container>
    </v-main>

    <v-footer
      fixed
      padless
      :style="
        'background-color: white;padding-bottom: ' + tabspadding * 0 + 'px;'
      "
    >
      <Menu />
    </v-footer>
  </v-app>

  <v-app
    v-else
    style="width: 100% !important"
    :style="$root.lang == 'ar' ? merkazi : ''"
  >
    <v-snackbar
      :timeout="5000"
      :value="true"
      absolute
      right
      top
      multi-line
      color="blue-grey darken-1"
      class="rounded-lg"
      elevation="24"
      v-model="snackbar"
      style="z-index: 1000"
    >
      <div style="display: flex; flex-direction: row; align-items: center">
        <v-avatar color="white" size="50" dark
          ><v-img src="/media/logos/logo.png" contain height="30"
        /></v-avatar>
        <div style="font-size: 1.1rem; font-weight: 500" class="px-2">
          {{ msg }}
        </div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      permanent
      color="primary"
      expand-on-hover
      app
      style="z-index: 10000"
      :right="$root.lang == 'ar'"
    >
      <KTBrand class="pt-3 px-2"></KTBrand>
      <KTAside></KTAside>
      <!--begin::Item-->
      <template v-slot:append>
        <router-link to="/profile" v-slot="{ href, navigate, isActive }">
          <v-list>
            <v-divider class="my-0" />

            <v-list-item @click="navigate" :href="href" class="px-2">
              <v-list-item-avatar size="40">
                <v-avatar v-if="$user.getCurrentUser()" tile size="40">
                  <v-img
                    v-if="$user.getCurrentUser().avatar != null"
                    :src="
                      $root.apiURL +
                        'assets/' +
                        $user.getCurrentUser().avatar.private_hash
                    "
                  ></v-img>
                  <unicon
                    v-else
                    name="user-circle"
                    :fill="isActive ? '#FFB300' : '#FFFFFF'"
                  ></unicon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  {{
                    $user.getCurrentUser().first_name +
                      " " +
                      $user.getCurrentUser().last_name
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </router-link>
      </template>

      <!--end::Item-->
    </v-navigation-drawer>
    <v-slide-x-transition>
      <v-navigation-drawer
        :right="$root.lang == 'ar'"
        width="300px"
        :style="$root.lang == 'ar' ? 'margin-right:56px' : 'margin-left:56px'"
        permanent
        app
        v-show="$root.admin.openside"
      >
        <RoleList v-if="$root.currentpage == 'users'" />
        <SettingsList v-if="$root.currentpage == 'AppSettings'" />
        <Conversations v-if="$root.currentpage == 'chat'" />
      </v-navigation-drawer>
    </v-slide-x-transition>
    <AppTop />
    <v-main
      :style="
        $root.lang == 'ar'
          ? 'padding-right:' + $root.admin.extramargin
          : 'padding-left:' + $root.admin.extramargin
      "
    >
      <v-container fluid>
        <transition name="fade-in-up">
          <router-view />
        </transition>
      </v-container>
    </v-main>
    <!--end::Content-->
    <!--begin::Footer-->
    <v-footer
      v-if="$root.currentpage != 'chat'"
      :style="
        $root.lang == 'ar'
          ? 'right:' + $root.admin.extramargin
          : 'left:' + $root.admin.extramargin
      "
      app
      inset
    >
      <div
        class="d-flex flex-row align-items-center justify-content-between"
        style="width: 100%"
      >
        <!--begin::Copyright-->
        <div class="">
          <span class="text-muted font-weight-bold mr-2">2022©</span>
          <a target="_blank" class="text-dark-75 text-hover-primary"
            >Doctory Care</a
          >
        </div>
        <!--end::Copyright-->
        <v-spacer />
        <!--begin::Nav-->
        <div
          style="width: fit-content; display: flex; width: fit-content"
          class=""
        >
          <a target="_blank" class="nav-link pr-3 pl-0">About</a>
          <a target="_blank" class="nav-link px-3">Team</a>

          <router-link :to="'/contact'" v-slot="{ href, navigate }">
            <a class="nav-link px-3" :href="href" @click="navigate">
              Contact
            </a>
          </router-link>
        </div>
        <!--end::Nav-->
      </div>
    </v-footer>
    <!--end::Footer-->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { Plugins } from "@capacitor/core";
const { PushNotifications, LocalNotifications } = Plugins;
//import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Layout",
  components: {
    KTAside: () => import("@/view/layout/aside/Aside.vue"),
    KTBrand: () => import("@/view/layout/brand/Brand"),

    Menu: () => import("@/view/layout/footer/Menu"),
    RoleList: () => import("@/view/pages/admin/users/RoleList.vue"),
    SettingsList: () => import("@/view/pages/admin/users/SettingsList.vue"),
    Conversations: () => import("@/view/Chat/Conversations.vue"),
    AppTop: () => import("./header/AppTop.vue")
  },
  data() {
    return {
      drawer: false,
      didcheckfmc: false,
      avatarAvail: false,
      avatar: null,
      showuser: false,
      notificationsSupported: false,
      notificationsEnabled: false,
      subscription: null,
      deviceInfo: "",
      screenratio: 0.0,
      systembarheight: 0,
      tabspadding: 0,
      isiOS: false,
      poppins: "font-family: 'Poppins' !important;",
      merkazi: "font-family: 'Markazi Text' !important;",
      //---- admin
      current: "",

      snackbar: false,
      msg: ""
    };
  },
  watch: {
    $route(to) {
      // console.log("page name", to.name);
      this.$root.currentpage = to.name;
      if (to.name == "users" || to.name == "AppSettings") {
        this.$root.admin.extramargin = "356px";
        this.$root.admin.openside = true;
      } else {
        this.$root.admin.extramargin = "56px";
        this.$root.admin.openside = false;
      }
    }
  },
  async beforeCreate() {
    /*if(this.$user.getCurrentUser() == null)
    {
      await this.$user
      .fetchCurrentUser()
      .then((d) => {
        // console.log("loaded current user and closing loader", d);
        this.$root.loader = false;
      })
      .then(() => {
        // console.log("coming from", this.$route)
        //if(this.$route.)
        this.$router.push({ name: "dashboard" });
      })
      .catch((e) => {
        // console.log("verify error", e);
        this.$store.dispatch(LOGOUT).finally(() => {
          this.$root.loader = false;
        });
      })
      .finally(() => {
        this.$root.loader = false;
      });
    }
    else{
      // console.log("user already there and it is", this.$user.getCurrentUser())
      
    }*/
    this.$root.loader = false;
  },
  beforeMount() {
    this.getDeviceInfo();
    // show page loading
    //this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
  },
  created() {},
  beforeDestroy() {
    // console.log("removing event listeners");
    if (this.deviceInfo.platform != "web") {
      PushNotifications.removeAllListeners();
    }
    this.$cap.removeAllListeners();
  },
  mounted() {
    // check if current user is authenticated

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
    //// console.log("current user is", this.$user.getCurrentUser());
    //// console.log(JSON.parse(localStorage.userInfo).avatar )
    if (JSON.parse(localStorage.userInfo).avatar != null) {
      this.avatarAvail = true;
      this.picture();
    }

    this.toggleSubscription();
    this.setBackListner();

    this.$util.EventBus.$on("showalert", data => {
      // console.log("showing alert", data);
      this.msg = data.msg;
      this.snackbar = true;
    });

    // Simulate the delay page loading
    /*setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);*/
  },
  methods: {
    setBackListner() {
      this.$cap.addListener("backButton", () => {
        // console.log("data from back btn is", data);
        // console.log("current route is", this.$route.name);
        if (this.$route.name != "dashboard") this.$router.go(-1);
      });
    },
    getcachedpages() {
      if (JSON.parse(localStorage.userInfo).role == 4) {
        return "dashboard, PatientDashboard, patient_info";
      }
    },
    async getDeviceInfo() {
      const { Device } = Plugins;

      this.deviceInfo = await Device.getInfo();

      localStorage.setItem("device", this.deviceInfo.operatingSystem);
    },

    registerMobileNotification() {
      if (this.deviceInfo.operatingSystem == "android") {
        LocalNotifications.requestPermission().then(() => {
          LocalNotifications.createChannel({
            id: "pop-notifications",
            name: "pop",
            description: "important notifications",
            importance: 5,
            visibility: 1,
            lightColor: "blue",
            vibration: true
          });
        });
      }

      PushNotifications.requestPermission().then(result => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          if (this.deviceInfo.operatingSystem == "android") {
            PushNotifications.createChannel({
              id: "popit",
              name: "popit",
              description: "important notifications",
              importance: 4,
              visibility: 1,
              lightColor: "blue",
              vibration: true
            });
          }
        } else {
          // Show some error
        }
      });

      PushNotifications.addListener("registration", token => {
        // get new (or existing user) from backend
        // console.log("registering notification");
        this.$notify.syncToken(
          token.value,
          this.deviceInfo,
          this.$user.getCurrentUser()
        );
      });

      PushNotifications.addListener("registrationError", error => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        async notification => {
          // console.log("Push received: " + JSON.stringify(notification));

          // if this is a push notification received when the app is in the foreground on Android
          if (
            this.deviceInfo.operatingSystem == "android" &&
            notification.data
          ) {
            // We schedule a LocalNotification 1 second later since Capacitor for Android doesn't show anything in this case
            // console.log("sending local notification");
            LocalNotifications.listChannels().then(() => {
              // console.log("channels", c);
            });
            await LocalNotifications.schedule({
              notifications: [
                {
                  title: notification.data.title,
                  body: notification.data.body,
                  id: new Date().getUTCMilliseconds(),
                  schedule: {
                    at: new Date(Date.now() + 1000)
                  },
                  extra: notification.data,
                  channelId: "pop-notifications"
                }
              ]
            });
            // console.log("scheduled notifications", notifs);
          }
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        notification => {
          console.log("Push action performed: " + JSON.stringify(notification));
        }
      );
    },

    async toggleSubscription() {
      const { Device } = Plugins;

      this.deviceInfo = await Device.getInfo();

      if (
        (this.deviceInfo.operatingSystem == "ios" ||
          this.deviceInfo.operatingSystem == "android") &&
        this.deviceInfo.platform != "web"
      ) {
        this.registerMobileNotification();
      } else {
        // console.log("fb service",this.$firebase)
        this.$firebase.getWebToken();
      }
    },

    isMobile() {
      this.$root.mobile = this.$vuetify.breakpoint.mobile;
      return this.$root.mobile;
    },
    async picture() {
      //// console.log(this.userInfo);
      if (JSON.parse(localStorage.userInfo).avatar) {
        await ApiService.get(
          "files",
          JSON.parse(localStorage.userInfo).avatar + "?fields=data"
        )
          .then(({ data }) => {
            // console.log(data.data);
            this.avatar = data.data.data.thumbnails[0].url;
          })
          .catch(() => {
            // console.log("error in here", e);
          });
      } else {
        return null;
      }
    },
    getInitials() {
      return (
        JSON.parse(localStorage.userInfo).first_name.charAt(0) +
        JSON.parse(localStorage.userInfo).last_name.charAt(0)
      );
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .theme--light >>> .v-snack--multi-line .v-snack__wrapper {
    width: 100vw;
  }
}
</style>
<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.v-data-table tr td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1rem !important;
  text-transform: uppercase;
}

.v-text-field--outlined fieldset {
  border-width: 2px !important;
}
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: rgba(0, 0, 0, 0.15);
}

.dot-green {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(27, 197, 189, 1);
  transform: scale(1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(27, 197, 189, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(27, 197, 189, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(27, 197, 189, 0);
  }
}

.dot-red {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(246, 78, 96, 1);
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(246, 78, 96, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(246, 78, 96, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(246, 78, 96, 0);
  }
}

.dot-yellow {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(255, 168, 0, 1);
  transform: scale(1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 168, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 168, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 168, 0, 0);
  }
}

.dot-blue {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(9, 113, 206, 1);
  transform: scale(1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(9, 113, 206, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(9, 113, 206, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(9, 113, 206, 0);
  }
}
</style>
